#modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  position: fixed;
  z-index: 1;
}

#modal-background {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  justify-content: center;
  position: fixed;
}

#modal-content {
  position: relative;
  background-color: #F3F3F3;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 20px 0px;
  width: auto;
  height: auto;
}

#x-button-holder {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

#x-button-holder:hover {
  cursor: pointer;
  background-color: #19212E;
}

#x-button-holder:hover .close-x-button {
  color: #F3F3F3;
}

.close-x-button {
  font-size: 24px;
  color: #19212E;
}
