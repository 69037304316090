@import '../../App.scss';


.game-show-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
    > h2 {
      display: block;
      width: 100%;
      margin: 20px 0 10px 58px;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -1px;
      box-sizing: border-box;
    }
}

.game-show-top {
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 12px;

  > img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }
}

.game-show-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 30px 50px;
  box-sizing: border-box;
}

