@import '../../App.scss';

#summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;

  padding: 30px 50px;
}

#user-top-section {
  height: calc(30% - 15px);
  width: fit-content;
  min-height: 200px;
  min-width: 200px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid $lighter-blue;

  img {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    object-fit: cover;
  }
}

#user-info-username {
  font-size: 18px;
  font-weight: 500;
  color: $darkest-blue;
}

#user-average-rating {
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  border-radius: 8px;
}

.star-holder {
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  background-color: $empty-star-blue;

  &.half-fill {
    background: linear-gradient(to right,  $medium-blue 0%, $medium-blue 50%, $empty-star-blue 50%, $empty-star-blue 100%);
  } 

  &.full-fill {
    background-color: $medium-blue;
  }

  .fa-star {
    color: $default-white;
  }
}

// .star-first-half {
//   width: 14px;
//   height: 28px;

//   border-radius: 4px 0 0 4px;
//   background-color: $empty-star-blue;
// }

// .star-second-half {
//   width: 14px;
//   height: 28px;

//   border-radius: 0 4px 4px 0;
//   background-color: $empty-star-blue;
// }

// .fill-star {
//   background-color: $medium-blue;
// }

#rating-number {
  margin-right: 4px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &+ span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

#no-reviews {
  font-style: italic;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

#user-bottom-section {
  min-height: 355px;
  width: 100%;
  max-width: 450px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border-radius: 8px;
  margin-top: 15px;
}

.user-info-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
}

.user-info-label {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 6px;
}

// .tags-label {
//   padding-top: 6px;
// }

.user-info-content {
  height: 100%;
  // font-size: 14px;

  .no-user-info {
    font-style: italic;
    font-size: 12px;
  }
}

.user-info-tag {
  display: inline-block;

  background-color: $medium-blue;
  color: $default-white;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;

  padding: 6px 10px;
  border-radius: 6px;
  margin: 0 4px 4px 0;

  cursor: pointer;

  &:hover {
    background-color: $lighter-blue;
  }

  &.playstyle {
    background-color: $default-white;
    color: $darkest-blue;
    border: 1px solid $empty-star-blue;
    cursor: default;
  }
}

.user-info-divider {
  width: 100%;
  height: 1px;
  background-color: $medium-blue;
  margin: 2px 0;
}

#moody-button {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 8px;
  border-radius: 6px;
  font-weight: 600;
  border: 1px solid $medium-blue;
  color: $medium-blue;
  cursor: pointer;

  i {
    padding-right: 4px;
  }

  > .fa-star {
    font-size: 14px;
  }
}

#moody-button:hover {
  background-color: $medium-blue;
  color: $default-white;
}