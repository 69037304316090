@import '../../App.scss';

#auth-cards-background {
  background-color: $darkest-blue;
  min-height: calc(100vh - 60px);
  height: 100%;
  min-width: 1270px;
}

#auth-cards-container {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  min-width: 1270px;
  max-height: fit-content;
  min-height: 650px;
  padding-top: 50px;
}

.auth-card {  
  box-sizing: border-box;
  min-height: 650px;
  width: 42.5%;
  border-radius: 12px;

  display: flex;
}

.hidden {
  opacity: 0;
}

.left-card {
  position: sticky;
  top: 110px;
  height: 100%;
  max-height: calc(100vh - 90px);
  min-height: calc(100vh - 130px);

  background-color: $default-white;
  color: $darkest-blue;
}

.right-card {
  background-color: $darkest-blue;
  color: $default-white;
}


// ANIMATION CODE TAKEN FROM https://sarthology.github.io/Animatopy/
// ALL CREDIT GOES TO https://daneden.me/

// @-webkit-keyframes flipInY {
//   from {
//     -webkit-transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
//     transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out;
//     opacity: 0;
//   }

//   to {
//     -webkit-transform: perspective(400px);
//     transform: perspective(400px);
//   }
// }

// @keyframes flipInY {
//   from {
//     -webkit-transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
//     transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out;
//     opacity: 0;
//   }

//   to {
//     -webkit-transform: perspective(400px);
//     transform: perspective(400px);
//   }
// }

// .flip-in-y {
//   -webkit-backface-visibility: visible;
//   backface-visibility: visible;
//   -webkit-animation-name: flipInY;
//   animation-name: flipInY;
// }

// .animate-flip-in {
//   -webkit-animation-duration: 0.5s;
//   animation-duration: 0.5s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
// }