@import '../../App.scss';

.comments-index-container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.comment-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $darker-blue;
  border-radius: 12px;
  padding: 15px;
  margin: 8px 0;
  width: 100%;

  &:last-child {
    padding: 25px 15px 15px 15px;
  }

  a > img {
    margin-right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    object-fit: cover;

    #initial-container {
      padding: 15px;
      font-size: 15px;
    }
  }

  > .comment-text-holder {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 30px;
    width: 100%;

    > .author-block {
      margin: 0 0 10px 0;

      > .author-username {
        font-weight: 600;
        font-size: 14px;
      }

      .timestamp {
        font-size: 12px;
        font-weight: 200;
      }
    }

    > .comment-body {
      font-weight: 300;
      font-size: 16px;
      line-height: 1.1em;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
        margin-left: 5px;
        border-radius: 8px;
        color: $highlight-orange;
      }

      .disable-btn {
        color: $empty-star-blue;
      }

      i:hover {
        background-color: $lightest-blue;
      }
      
      .disable-btn:hover {
        background-color: unset;
        cursor: not-allowed;
      }

      .errors {
        margin-top: 10px;
      }

      textarea {
        box-sizing: border-box;
        width: calc(100% - 5px);
        padding: 8px;
        outline: none;
        color: $darkest-blue;
        border-radius: 6px;
        font-weight: 400;
        font-size: 14px;
      }

      textarea:hover {
        outline: 1px solid $lighter-blue;
      }
      
      textarea:focus {
        outline: 2px solid $lightest-blue;
      }
    }
  }
}


#comment-body-container {
  display: flex;
  justify-content: space-between;
  // position: relative;
  flex-direction: row;
  width: 100%;
  
  .comment-buttons-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $highlight-orange;

    .fa-paper-plane {
      width: 0;
      height: 0;
      padding: 13px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .fa-paper-plane:hover {
      background-color: $lightest-blue;
    }

    .disable-btn {
      color: $empty-star-blue;
    }
    
    .disable-btn:hover {
      background-color: unset;
      cursor: not-allowed;
    }
  

    .fa-xmark {
      font-size: 16px;
      width: 0;
      height: 0;
      padding: 13px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .fa-xmark:hover {
      background-color: $lightest-blue;
    }

    .fa-pen {
      width: 0;
      height: 0;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .fa-pen:hover {
      background-color: $lightest-blue;
    }

    .fa-trash {
      width: 0;
      height: 0;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .fa-trash:hover {
      background-color: $lightest-blue;
    }
  }
}


.comment-edit-section {
  width: 100%;
  
  textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    outline: none;
    font-family: $default-font;
    border-radius: 6px;
    font-size: 14px;
  }
}

#comment-text-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $darkest-blue;
}

#comment-count {
  font-size: 10px;
  font-weight: 200;
  margin-top: 7px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  color: $default-white;
  position: absolute;
  bottom: -14px;
  left: 0;
}

#edit-comment-count {
  font-size: 10px;
  font-weight: 200;
  margin-top: 5px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  color: $default-white;
}

.bad-count {
  color: $highlight-orange;
  font-weight: 300;
}

#delete-comment-modal {
  width: 440px;
}