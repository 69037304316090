@import '../../App.scss';

#about-container > #auth-cards-background {
    // display: flex;
    // flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    min-width: 450px;
    // background-color: $medium-blue;
    background-image: linear-gradient(to right, $darker-blue, $darkest-blue, $darkest-blue, $darkest-blue, $darker-blue);

    > div {
        margin: 20px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
}

.about-card {
    display: flex;
    background-color: $default-white;
    border-radius: 12px;
    overflow: hidden;
    width: 30vw;
    min-width: 375px;
    margin: 20px;
    > img {
        width: 15vw;
        min-width: 150px;
    }
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 20px;
        margin: 0 auto;

        p, div, a {
            padding: 5px;

            > a {
                font-size: 24px;
                &:first-child {
                    margin-right: 10px;
                }
            }
        }

        p:first-child {
            font-weight: 500;
        }

        p:nth-child(2) {
            color: $medium-blue;
        }
    }
}

// .about-card:nth-child(2) {
//     margin-left: 5vw;
// }