@import '../../App.scss';


#edit-profile-container-holder {
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px;
}

#edit-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 450px;

  > h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  > .user-info-divider {
    margin: 5px 0 22px 0;
  }
}

.edit-profile-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 12px;
  box-sizing: border-box;

  :first-child {
    align-self: center;
  }
}

.edit-profile-label {
  font-size: 14px;
  font-weight: 600;
}

.edit-profile-field {
  width: 67%;

  > input[type="text"] {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
    border: none;
    color: $darkest-blue;
    background-color: white;
  }
  
  > input[type="text"]:hover {
    outline: 1px solid $lighter-blue;
  }

  > input[type="text"]:focus {
    outline: 2px solid $lightest-blue;
  }

  > textarea {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
    color: $darkest-blue;
  }

  > textarea:hover {
    outline: 1px solid $lighter-blue;
  }

  > textarea:focus {
    outline: 2px solid $lightest-blue;
  }
}

.edit-profile-row#edit-pfp {
  display: flex;
  align-items: center;
}

.edit-profile-label#edit-pfp {
  display: flex;
  align-items: center;
  position: relative;

  > img {
    width: 53.5px;
    height: 53.5px;
    border-radius: 8px;
    margin-top: 1px;
    object-fit: cover;
    cursor: pointer;
  }
}

.edit-profile-field#edit-username {
  width: 100%;
  margin-right: 22px;

  .edit-profile-label {
    width: 100%;
    padding-left: 8px;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
  }
}

#change-pfp-btn-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 53.5px;
  width: 53.5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid $medium-blue;

  font-size: 12px;
  font-weight: 600;
  background-color: #212d40a8;
  color: $default-white;
  cursor: pointer;

  i {
    font-size: 18px;
    margin-bottom: 4px;
  }
}

#change-pfp-btn-container:hover {
  background-color: #212d40ef;
}

.edit-profile-row#edit-about {
  height: 90px;
}

.edit-profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;

  .edit-profile-label {
    width: 100%;
    margin: 0 0 6px 18px;
  }

  .edit-profile-field {
    width: 100%;

    select {
      width: 100%;
      height: 28px;
      font-size: 14px;
      font-weight: 500;
      font-family: $default-font;
      color: $darkest-blue;
      margin-top: 10px;
      padding: 4px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }

    select-selected {
      background-color: $darkest-blue;
    }

    select:hover {
      outline: 1px solid $lighter-blue;
    }

    select:focus {
      outline: 2px solid $lightest-blue;
    }
  }
}

.display-faves-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p > i {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    text-align: center;
    margin-left: 5px;
  }
}

.edit-profile-field#play-style {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .user-info-tag {
    display: flex;
    align-items: center;
  }
}

.select-play-style-circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border-radius: 100%;
  border: 1px solid $darkest-blue;
  background-color: $default-white;

  &.selected-circle {
    background-color: $highlight-orange;
  }
  
  &:hover {
    background-color: #fbc089;
  }
}

#edit-profile-bottom {
  width: 150px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.save-btn {
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;
  background-color: $medium-blue;
  color: $default-white;
  cursor: pointer;
}

.save-btn:hover {
  background-color: $darkest-blue;
}

.cant-save {
  background-color: $empty-star-blue;
  color: $default-white;
  cursor: not-allowed;
}

.cant-save:hover {
  background-color: $empty-star-blue;
}

.cancel-btn {
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;
  background-color: $lightest-blue;
  color: $default-white;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: $lighter-blue;
}