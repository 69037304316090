@import '../../App.scss';

.posts-index-container {
  box-sizing: border-box;
  width: 100%;
  min-width: 410px;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: $default-white;
}

#game-show-shadow-anchor {
  position: sticky;
  top: 110px;
  width: 100%;
  height: 0;
}

#game-show-shadow {
  position: absolute;
  top: -50px;
  width: calc(100% + 2px);
  height: 50px;
  background-image: linear-gradient(#11151c, #11151c05);
}

.post-index-item {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  border: 1px solid $lighter-blue;
  border-radius: 12px;
  color: $default-white;
  margin-bottom: 30px;

  .post-top-section {
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
  }

  .post-index-title {
    display: flex;
    justify-content: space-between;
  }

  .author-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    > .timestamp {
      font-size: 14px;
      font-weight: 300;
      color: $empty-star-blue;
    }
  }

  &:last-child {
    margin-bottom: 20px;
  }

  .author-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    a > img {
      margin-right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      object-fit: cover;
    }

    .author-username {
      font-weight: 600;
      letter-spacing: 0.05rem;
    }
  }

  .post-index-title {
    font-size: 24px;
    font-weight: 500;
  }

  .post-index-description {
    font-size: 18px;
    line-height: 1.15em;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .profile-post-tag {
    display: inline-block;
    font-style: italic;
    font-size: 14px;
    font-weight: 300;
    padding: 6px 10px;
    margin-bottom: 5px;
    border-radius: 6px;
    background-color: $lighter-blue;

    &:hover {
      background-color: $medium-blue;
    }
  }

  .post-comment-separator {
    width: 100%;
    height: 1px;
    background-color: $lighter-blue;
    margin: 20px 0;
  }
}

.post-buttons-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-left: 6px;

  .fa-solid {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    margin-left: 2px;
    border-radius: 6px;
    padding: 8px;
    color: $highlight-orange;
  }

  .fa-solid:hover {
    background-color: $lighter-blue;
  }
}
